<script setup>
import {computed, onMounted, onUnmounted, ref, watch} from 'vue';
import {useSwipe} from '@vueuse/core'
import HeadNew from '@/components/Head.vue'
import {useModalScrollingFix} from '@/hooks/useModalScrollingFix'

const modalScrollingFix = useModalScrollingFix()

const props = defineProps({
  imageUrl: {
    type: Array,
  },
  anchor: {
    type: String,
  },
  animation: {
    type: String,
    default: 'fade', // blur, fade
  },
  imageAlt: {
    type: String, // []
  },
  withDownload: {
    type: Boolean,
  },
  withClose: {
    type: Boolean,
    default: true,
  },
  withFocusOnClose: {
    type: Boolean,
  },
  withCloseOnEscape: {
    type: Boolean,
  },
  closeOnClickOutside: {
    type: Boolean,
  },
  maxHeight: {
    type: String,
    default: '80vh',
  },
  maxWidth: {
    type: String,
    default: '80vw',
  },
  backdropColor: {
    type: String,
    default: '#171717',
  },
  panoramaCurrentIndex: {
    type: Number,
    default: 0,
  },
  zoom: {
    type: Boolean,
    default: false,
  }
})

const cachedImageUrl = computed(() => {
  return props.imageUrl.map(i => imagesLinkCache.cache(i))
})

const emits = defineEmits(['close']);
const panoramaCurrentIndex = ref(props.panoramaCurrentIndex)
const disappear = ref(false)

const imageEl = ref(null)
const zoomImageEl = ref(null)

const state = reactive({
  width: 0,
  height: 0,
  contentWidth: 0,
  contentHeight: 0,
  minScale: 0,
})

const onClose = () => {
  disappear.value = true;
  modalScrollingFix.close()
  setTimeout(() => {
    emits('close');
  }, 500);
};

const onKeydown = (event) => {
  if (props.withCloseOnEscape && event.key === 'Escape') {
    onClose();
  }
};

const onBackdropClick = () => {
  if (props.closeOnClickOutside) {
    onClose()
  }
};

const isPreviousDisabled = computed(() => panoramaCurrentIndex.value === 0)
const isNextDisabled = computed(() => panoramaCurrentIndex.value === props.imageUrl.length - 1)

const handlePrevious = () => {
  panoramaCurrentIndex.value--
}

const handleNext = () => {
  panoramaCurrentIndex.value++
}

const closeButtonRef = ref()

function calcZoomerSize() {
  if (!props.zoom) {
    return
  }
  const rect = zoomImageEl.value.getBoundingClientRect()
  state.width = rect.width
  state.height = rect.height
  const img = new Image();
  img.onload = function () {
    state.contentWidth = img.width
    state.contentHeight = img.height
    if(state.contentWidth < state.contentHeight) {
      state.minScale = state.width / state.contentWidth
    } else {
      state.minScale = Math.min(state.width, state.height) / Math.max(state.contentWidth, state.contentHeight)
    }
  };
  img.src = props.imageUrl[panoramaCurrentIndex.value]
}

onMounted(() => {
  modalScrollingFix.open()
  if (props.withFocusOnClose && closeButtonRef.value) {
    closeButtonRef.value.focus()
  }
  calcZoomerSize()
  document.addEventListener('resize', calcZoomerSize)
})

onUnmounted(() => {
  document.removeEventListener('resize', calcZoomerSize)
})

const {isSwiping, direction, lengthX, lengthY} = useSwipe(imageEl, {
  passive: false,
  onSwipe(e) {

  },
  onSwipeEnd(e, direction) {
    switch (direction) {
      case 'right':
        if (!isPreviousDisabled.value) {
          handlePrevious()
        }
        break
      case 'left':
        if (!isNextDisabled.value) {
          handleNext()
        }
        break
    }
  },
})

import {reactive} from 'vue';
import PinchScrollZoom from '@coddicat/vue-pinch-scroll-zoom';
import {imagesLinkCache} from '@/utils/imagesLinkCache.js'

const zoomer = ref();

function onEvent(name, e) {

}

// function reset() {
//   zoomer.value?.setData({
//     scale: 1,
//     originX: 150,
//     originY: 200,
//     translateX: -100,
//     translateY: -50
//   });
// }

</script>

<template>
  <Transition :name="animation" appear>
    <div
        class="fullscreen-image"
        v-if="imageUrl && !disappear"
        @keydown="onKeydown"
        tabindex="0"
        role="dialog"
        aria-modal="true"
        aria-label="Fullscreen Image"
    >
      <div
          class="backdrop"
          @click="onBackdropClick"
      >
        <head-new
            style="margin-bottom: 12px"
            :back-action="onClose"
        />

        <div v-if="!props.zoom" class="touchArea swipe" ref="imageEl">
          <template v-if="Array.isArray(imageUrl)">
            <transition-group name="list" tag="div" class="image-container">

              <template v-for="(currImage, index) in imageUrl" :key="currImage + index">
                <img
                    v-if="panoramaCurrentIndex === index"
                    :src="currImage"
                    :alt="Array.isArray(imageAlt) ? imageAlt[panoramaCurrentIndex] : imageAlt"
                />
              </template>

            </transition-group>
          </template>
          <template v-else>
            <div class="image-container">
              <img :src="imageUrl" :alt="Array.isArray(imageAlt) ? imageAlt[panoramaCurrentIndex] : imageAlt"/>
            </div>
          </template>
        </div>

        <div v-if="props.zoom" class="touchArea" ref="zoomImageEl">
          <PinchScrollZoom
              v-if="state.contentWidth && state.contentHeight"
              ref="zoomer"
              within
              centered
              key-actions
              :width="state.width"
              :height="state.height"
              :min-scale="Math.min(1, state.minScale)"
              :max-scale="5"
              :content-width="state.contentWidth"
              :content-height="state.contentHeight"
              @scaling="e => onEvent('scaling', e)"
              @startDrag="e => onEvent('startDrag', e)"
              @stopDrag="e => onEvent('stopDrag', e)"
              @dragging="e => onEvent('dragging', e)"
              :scale="state.minScale"
          >
            <img
                :src="imageUrl[0]"
                :alt="Array.isArray(imageAlt) ? imageAlt[panoramaCurrentIndex] : imageAlt"
                :width="state.contentWidth"
                :height="state.contentHeight"
            />
          </PinchScrollZoom>
        </div>

      </div>
    </div>
  </Transition>
</template>

<style scoped>

.fullscreen-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: v-bind(backdropColor);
  z-index: -1;
  display: flex;
  flex-direction: column;
}

.touchArea {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swipe {
  .image-container {
    justify-content: center;
    align-items: center;
    max-width: calc(100dvw);
    max-height: calc(100dvh - 68px);
    overflow: hidden;
    flex-wrap: nowrap;
    position: relative;
  }

  img {
    object-fit: contain;
    max-width: inherit;
    max-height: inherit;
  }
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter {
  opacity: 1;
}

.blur-enter-from,
.blur-leave-to {
  filter: blur(5px);
  transform: translate3d(0, 0, 0);
  opacity: 0;
}

.blur-enter-active,
.blur-leave-active {
  transition: filter .5s, opacity .5s;
}

.blur-enter {
  filter: blur(0px);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.list-leave-active {
  position: absolute;
  transition: all 0.5s ease;
}

.list-move,
.list-enter-active {
  transition-delay: 0.5s;
  transition: all 0.5s ease;
}

.list-enter-from {
  opacity: 0;
  transform: translateX(-30px);
}

.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
