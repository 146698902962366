<template>
  <div class="pageWrapper">
    <HeadNew
        :back-action="() => router.push({name: 'Home'})"
        :is-loading="state.isLoading"
        :title="state.collection?.title"
    />
    <div
        class="main_container pageWrapperOverflow"
        style="padding: 12px 16px 24px 16px"
    >
      <div>
        <Card style="overflow: hidden">
          <template v-if="state.isLoading">
            <Skeleton size="18" width="100%" style="margin-bottom: 8px"/>
            <Skeleton
                v-for="i in 6"
                variant="text"
                :width="String(200 + (i % 2) * 50 + (i * 20))"
                height="14"
            />
            <Skeleton size="18" width="160" style="margin-bottom: 8px; margin-top: 16px"/>
            <div>
              <Skeleton
                  width="60%"
                  height="90"
              />
            </div>
          </template>
          <template v-else>
            <LessonContentHTML
                :data="{html: state.collection?.html}"
                :background="'transparent'"
            />
            <template v-if="state.collection?.authors && state.collection.authors.length">
              <Typography variant="v16-700" style="margin-bottom: 8px; margin-top: 16px">
                Авторы подборки:
              </Typography>

              <swiper
                  :slidesPerView="'auto'"
                  :spaceBetween="8"
                  :freeMode="true"
                  class="collectionsSwiper"
              >
                <swiper-slide v-for="author of state.collection.authors" class="slide">
                  <Card
                      style="background-color: #2F2F2F; padding: 12px;display: flex; gap: 12px; align-items: center;"
                      @click="router.push({name: 'CollectionUserProfile', params: { userId: author.id, collectionId: props.collectionId}})"
                  >
                    <Avatar
                        size="medium"
                        :image="imagesLinkCache.cache(author.preview_avatar)"
                    />
                    <div style="max-width: calc(65% - 12px)">
                      <Typography variant="v14-700" one-line>
                        {{ author.first_name }}
                      </Typography>
                      <Typography variant="v14-700" one-line>
                        {{ author.last_name }}
                      </Typography>
                    </div>
                  </Card>
                </swiper-slide>
              </swiper>
            </template>

            <AppButton
                v-if="!state.isLoading"
                variant="secondary"
                full-width
                style="margin-top: 16px"
                @click="openExternalURL('https://forms.yandex.ru/u/67580b1402848f1fa01de014/?page=1')"
            >
              Как попасть в подборку?
            </AppButton>

            <AppButton
                v-if="state.collection.lesson"
                full-width
                variant="secondary"
                style="margin-top: 16px"
                @click="router.push({name: 'CollectionLesson', params: {collectionId: props.collectionId}})"
            >
              {{ state.collection.lesson.title }}
            </AppButton>

          </template>
        </Card>

        <div
            v-if="!state.isLoading"
            style="margin-top:16px; display: flex; flex-direction: column; gap: 8px"
        >
          <UserCard
              v-for="user in state.collection.users"
              :data="user"
              :profileClick="() => router.push({name: 'CollectionUserProfile', params: { userId: user.id, collectionId: state.collection.id}})"
              :hideActions="true"
              :hideLocation="true"
              :lineClamp="3"
          >
            <AppButton
                v-if="!user.is_contact"
                full-width
                variant="secondary"
                @click="(e) => {e.stopPropagation(); addToContact(user)}"
            >
              Добавить в визитницу
            </AppButton>
            <AppButton
                v-else
                full-width
                variant="secondary"
                @click="(e) => {e.stopPropagation(); state.userContactShown = user}"
            >
              В визитнице
            </AppButton>
          </UserCard>
        </div>
        <div
            v-else
            style="margin-top:16px; display: flex; flex-direction: column; gap: 8px"
        >
          <UserCard
              :is-loading="true"
              :data="null"
              hideActions
          />
          <UserCard
              :is-loading="true"
              :data="null"
              hideActions
          />
          <UserCard
              :is-loading="true"
              :data="null"
              hideActions
          />
        </div>

        <bottom-drawer
            v-if="!state.isLoading"
            :isShown="state.userContactShown"
            title="Удаление из визитницы"
            @close="state.userContactShown = false"
        >
          <template v-if="state.userContactShown">
            <Typography variant="v14-600" color="#D3D2D2">
              Ты действительно хочешь удалить
              {{ state.userContactShown.first_name }} {{ state.userContactShown.last_name }}
              из визитницы?
            </Typography>

            <div style="display: flex; gap: 4px; margin: 16px 0">
              <appButton
                  full-width
                  class="submitButton"
                  variant="secondary"
                  :disabled="state.isDeleting"
                  @click="state.userContactShown = false"
              >
                Отменить
              </appButton>
              <appButton
                  full-width
                  class="submitButton"
                  variant="secondary"
                  color="#FF303A"
                  :disabled="state.isDeleting"
                  :loading="state.isDeleting"
                  @click="() => evaluated(0, state.userContactShown)"
              >
                Удалить
              </appButton>
            </div>
          </template>
        </bottom-drawer>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onBeforeMount, reactive} from 'vue'
import HeadNew from '@/components/Head.vue'
import {router} from '@/router/router.js'
import Card from '@/components/UI/Card.vue'
import LessonContentHTML from '@/views/study/lessonContent/LessonContentHTML.vue'
import Typography from '@/components/UI/Typography.vue'
import {Swiper, SwiperSlide} from 'swiper/vue'
import {imagesLinkCache} from '@/utils/imagesLinkCache.js'
import Avatar from '@/components/profile/Avatar.vue'
import Skeleton from '@/components/UI/Skeleton.vue'
import AppButton from '@/components/UI/AppButton.vue'
import {openExternalURL} from '@/utils/openExternalURL.js'
import UserCard from '@/components/UserCard.vue'
import BottomDrawer from '@/components/BottomDrawer.vue'
import {appAxios} from '@/axios.js'
import store from '@/store/store.js'
import {yandexMetrika} from '@/external/yandexWebisor.js'

const props = defineProps({
  collectionId: {
    type: String,
  }
})

const state = reactive({
  isLoading: true,
  collection: null,
  userContactShown: false,
  isDeleting: false,
})

onBeforeMount(async () => {
  state.isLoading = true

  const {response, axiosResponse} = await appAxios.collections.getById(props.collectionId)
  let res = response
  let axiosRes = axiosResponse

  state.collection = res.data

  axiosRes.then(async r => {
    state.collection = res.data
  })

  state.isLoading = false

  yandexMetrika('openCollection', {
    openCollectionTitle: state.collection.title,
    openCollectionId: props.collectionId,
  })
})

async function addToContact(user) {
  user.isLoading = true
  const res = await appAxios.myContacts.addContact(user.id, 5)

  Object.assign(user, {
    ...res.data.contact_user,
    is_contact: res.data.id,
  })

  user.isLoading = false
}

async function evaluated(rate, user) {
  const userId = store.state.user.profile.id

  if (rate === 0) {
    state.isDeleting = true
    await appAxios.myContacts.removeContact(user.is_contact, userId)
    Object.assign(user, {
      ...user,
      is_contact: null,
    })
    state.isDeleting = false
  } else {
    const res = await appAxios.myContacts.updateContact(user.is_contact, rate)
    Object.assign(user, {
      ...res.data,
      is_contact: res.data.id,
    })
  }
  state.userContactShown = false
}

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.pageWrapper {
  min-height: calc(100vh - 80px);
  min-height: calc(100dvh - 80px);
  max-height: calc(100vh - 80px);
  max-height: calc(100dvh - 80px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.pageWrapperOverflow {
  overflow-y: auto;
}

.collectionsSwiper {
  overflow: visible;
  height: 90px;
}

.slide {
  width: calc(65%);
  min-width: 88px;
}

</style>
