<template>
  <div class="study pageWrapper">
    <head-new
        page_title="Курсы"
        :back-action="() => router.push({name: 'Home'})"
    />

    <MarketplaceTabs/>

    <div class="pageWrapperOverflow">
      <template v-if="state.isLoading">
        <div
            class="main_container course_list"
        >
          <div class="sec_title">

          </div>
          <div class="list_item_wrap">

            <div class="item"
                 v-for="_ in 5"
            >
              <div class="imgWrapper">
                <Skeleton/>
              </div>
              <div class="info">
                <div class="title">
                  <Skeleton
                      variant="text"
                      :width="String(100 + (Math.random() % 2) * 50 + (Math.random() * 20))"
                      height="16"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="!state.isLoading">
        <div
            v-if="state.courses.length > 0"
            class="main_container course_list"
            :class="state.promos.length ? 'course_list_top' : ''"
        >
          <div class="list_item_wrap">

            <div
                class="item"
                :class="{disabled: userCourse.enrollment_type === courseEntrollmentTypes.promo && userCourse.status === studentCoursesStatuses.deleted}"
                v-for="userCourse in state.courses"
                @click="openCourse(userCourse.id)"
            >
              <div
                  class="imgWrapper"
                  :class="{
                    full: userCourse.course.preview_cover_image,
                  }"
              >
                <div
                    v-if="userCourse.course.preview_cover_image"
                    class="img"
                    :style="{backgroundImage: urlToCssURL(imagesLinkCache.cache(userCourse.course.preview_cover_image))}"
                />
                <div
                    v-else
                    class="img"
                    :style="{backgroundImage: urlToCssURL(imagesLinkCache.cache(userCourse.course.preview_image))}"
                />
              </div>
              <div class="info">
                <div
                    class="title"
                >
                  {{ userCourse.course.title }}
                </div>
                <Typography
                    variant="text2"
                    color="#FFF"
                    class="desc"
                >
                  {{ userCourse.course.description }}
                </Typography>

                <div class="delimiter"/>

                <CourseProgressbar
                    :is-loading="state.isLoading"
                    :text="getCourseProgressText(userCourse.progress)"
                    :completed="getCourseProgressPercent(userCourse.progress)"
                    borderLess
                />
              </div>
            </div>
          </div>
        </div>
        <StudyEmpty
            v-if="state.courses.length === 0"
            :hasPromos="state.promos.length > 0"
        />
      </template>
    </div>
  </div>
</template>

<script setup>
import HeadNew from '@/components/Head.vue'
import {Swiper, SwiperSlide} from 'swiper/vue'
import 'swiper/css'
import StudyEmpty from '@/views/study/StudyEmpty.vue'
import {onBeforeMount, onMounted, onUnmounted, reactive, watch} from 'vue'
import {appAxios} from '@/axios'
import {courseEntrollmentTypes} from '@/configs/courseEntrollmentTypes'
import {router} from '@/router/router'
import CourseProgressbar from '@/components/UI/CourseProgressbar.vue'
import {timeDiff} from '@/utils/timeLeft'
import {urlToCssURL} from '@/utils/urlToCssURL'
import {getCourseProgressPercent, getCourseProgressText} from '@/utils/courseUtils'
import {imagesLinkCache} from '@/utils/imagesLinkCache'
import MarketplaceTabs from '@/views/study/StudyTabs.vue'
import Skeleton from '@/components/UI/Skeleton.vue'
import Typography from '@/components/UI/Typography.vue'
import {subscriptionStatuses} from "@/configs/subscriptionStatuses.js"
import store from "@/store/store.js";
import {coursesStatuses, studentCoursesStatuses} from "@/configs/coursesStatuses.js";
import {indexedStore} from '@/indexedDB/indexedStore.js'

const state = reactive({
  subscriptionExpired: false,
  isLoading: true,
  promos: [],
  courses: [],
  timeNow: Date.now(),
  interval: null,

  subscription: null,
  isSubscriptionActive: null,
})

// const end = new Date('2024-03-13T22:14:34.237162+03:00')
const now = Date.now()

onMounted(() => {
  state.interval = setInterval(() => {
    state.timeNow = Date.now()
  }, 1e3)
})

onUnmounted(() => {
  clearInterval(state.interval)
})

onBeforeMount(async () => {
  state.subscription = store.state.user.directories.subscription_price_list[0]
  const userSubscription = store.state.user.profile?.subscriptions[0]
  if (userSubscription && userSubscription.status === subscriptionStatuses.active) {
    state.isSubscriptionActive = true
  }

  // const response = await appAxios.myCourses.fetchAll()

  const {response, axiosResponse} = await appAxios.myCourses.fetchAll()
  // res = response
  // axiosRes = axiosResponse

  if (state.isSubscriptionActive) {
    state.courses = response.data.results
  } else {
    const promoCourses = response.data.results.filter(course => {
      return course.enrollment_type === courseEntrollmentTypes.promo && course.status !== studentCoursesStatuses.deleted
    })
    const courses = response.data.results.filter(course => {
      return !(course.enrollment_type === courseEntrollmentTypes.promo && course.status !== studentCoursesStatuses.deleted)
    })
    state.promos = promoCourses
    state.courses = courses
  }

  axiosResponse.then(async response => {
    if (state.isSubscriptionActive) {
      state.courses = response.data.results
    } else {
      const promoCourses = response.data.results.filter(course => {
        return course.enrollment_type === courseEntrollmentTypes.promo && course.status !== studentCoursesStatuses.deleted
      })
      const courses = response.data.results.filter(course => {
        return !(course.enrollment_type === courseEntrollmentTypes.promo && course.status !== studentCoursesStatuses.deleted)
      })
      state.promos = promoCourses
      state.courses = courses
    }
  })

  state.isLoading = false
})

watch(() => state.timeNow, async t => {
  if (!state.roulette?.promo_ends_at) {
    return
  }
  if ((new Date(state.roulette.promo_ends_at)) - t < 0) {
    state.roulette = {}
    state.promos = []
    // state.isLoading = true
    // try {
    //   const resRoulette = await appAxios.roulette.check()
    //   state.roulette = resRoulette.data
    //   state.isLoading = false
    // } catch (err) {
    //   if (err?.response?.data?.detail) {
    //     state.roulette = {
    //       detail: err.response.data.detail,
    //     }
    //     // state.isLoading = false
    //     return
    //   }
    //   throw err
    // }
  }
})

async function openCourse(id) {
  await indexedStore.slices.myCourses.invalidate(`myCourses-fetchOne-${id}`)
  router.push({
    name: 'StudyCourse',
    params: {userCourseId: id},
  })
}
</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.pageWrapper {
  margin-bottom: -$homeLayoutPaddingBottom;
  min-height: calc(100dvh - $bottomMenuOffset);
  max-height: calc(100dvh - $bottomMenuOffset);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.pageWrapperOverflow {
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 24px;
}

.title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
}

.desc {
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.delimiter {
  margin: 16px 0;
  border: 1px solid #2B2B2B;
}

.study {
  .study_home {
    background: linear-gradient(60deg, #fc5e1a 0%, #f7b733 100%);
    padding: 16px 0 80px;
    position: relative;

    &.all {
      padding: 16px 72px 16px 0;
      height: auto;
      min-height: 104px;
    }

    .bg {
      position: absolute;
      top: 0;
      right: 0;
    }

    .title {
      color: #fff;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      margin-bottom: 2px;
    }

    p {
      color: rgba(255, 255, 255, 0.8);
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }
  }

  .study_slider {
    .main_container {
      position: relative;
    }

    .swiper {
      overflow: visible;
    }

    .swiper-slide {
      width: 280px;
    }

    .slider_wrapper {
      position: absolute;
      top: 0;
      left: 16px;
      right: 16px;
      transform: translateY(-50%);
    }

    .slide_item {
      border-radius: 16px;
      background: #222;
      padding: 12px;
      cursor: pointer;
    }

    .slide_item_top {
      display: flex;
      gap: 12px;
      margin-bottom: 6px;

      .imgWrapper {
        width: 80px;
        height: 80px;
        border-radius: 8px;
        background: #3B3B3B;
        padding: 4px;
      }

      .img {
        width: 72px;
        height: 72px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .type {
        color: #d9b14b;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        margin-bottom: 2px;
      }

      .title {
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
      }
    }

    .slide_item_bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .time {
        display: flex;
        align-items: center;
        gap: 4px;

        span {
          color: #ff3f48;
          text-align: center;
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
        }
      }

      .protsent {
        display: flex;
        align-items: center;
        gap: 8px;

        span {
          font-size: 12px;
          font-weight: 500;
          color: #A6A5A5;
        }

        .icon {
          position: relative;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .course_list {
    margin-top: 24px;

    .sec_title {
      color: #fff;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      margin-bottom: 12px;
    }

    .list_item_wrap {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .item {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      border-radius: 16px;
      overflow: hidden;
      background: #222;
      padding: 12px;
      gap: 12px;
      cursor: pointer;

      &.disabled {
        opacity: .5;
        cursor: default;
        pointer-events: none;
      }

      .imgWrapper {
        margin: -12px -12px 0 -12px;
        height: 152px;
        border-radius: 16px 16px 0 0;
        display: flex;
        justify-content: center;
        background: #3B3B3B;
        padding: 4px;

        &.full {
          height: 144px;
          padding: 0;

          .img {
            width: 100%;
            height: 144px;
            border-radius: 8px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
          }
        }
      }

      .img {
        width: 144px;
        height: 144px;
        border-radius: 16px;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    .info {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: space-between;

      .title {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: #fff;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .course_list_top {
    //margin-top: 84px;
  }
}
</style>
