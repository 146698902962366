<template>
  <div style="position: absolute; margin: 8px; left: 45%; background: #fff; z-index: 1000">
    <input
        type="text"
        style="width: 24px; display: block"
        @input="e => state.top = e.target.value"
    >
    <input
        type="text"
        style="width: 24px; display: block"
        @input="e => state.bottom = e.target.value"
    >
  </div>
  <PWABlock
      v-if="!state.isStandalone"
  />
  <NewVersionAvailable
      v-else-if="store.state.app.isNewVersionAvailable"
  />
  <SomethingGoingWrong
      v-else-if="store.state.app.isCrashed"
  />
  <NoConnection
      v-else-if="!store.state.app.isOnline"
  />
  <!--   :key="$route.fullPath"-->
  <router-view
      :key="$route.fullPath"
      v-else-if="!store.state.user.isRestoring"
  />
  <transition
      v-show="store.state.user.isRestoring"
      name="fade"
  >
    <SplashScreen v-if="state.splashScreenNeeded"/>
  </transition>

  <component
      v-if="store.state.app.messageComponent"
      :is="messageComponents[store.state.app.messageComponent]"
      :data="store.state.app.messageComponentData"
  />
</template>

<script setup>
import {onBeforeMount, onMounted, onUnmounted, reactive, watch} from 'vue'
import store from './store/store'
import SplashScreen from '@/views/SplashScreen.vue'
import {imagesLinkCache} from '@/utils/imagesLinkCache'
import NoConnection from '@/views/system/NoConnection.vue'
import SomethingGoingWrong from '@/views/system/SomethingGoingWrong.vue'
import {pwaUtils} from "@/utils/pwaUtils.js"
import PWABlock from "@/views/system/PWABlock.vue"
import NewVersionAvailable from '@/views/system/NewVersionAvailable.vue'
import {yandexMetrika} from "@/external/yandexWebisor.js"
import {messageComponents} from '@/components/messageComponents/messageComponents.js'
import {number} from 'yup'

const state = reactive({
  splashScreenNeeded: true,
  isStandalone: pwaUtils.isStandalone(),
  checkInterval: null,
// to remove
  appElement: null,
  top: 0,
  bottom: 0,
})

// to remove

function onFocus(e) {
  const tags = ['INPUT', 'TEXTAREA']
  const focused = e.target
  if (focused && focused && tags.includes(focused.tagName)) {
    console.log('onFocus', e.target)
    const el = document.querySelector('#app')
    console.log('state', state)
    if(state.top) {
      el.style.top = `${state.top}px`
    }
    if(state.bottom) {
      el.style.bottom = `${state.bottom}px`
    }
  }
}

function onBlur(e) {
  const tags = ['INPUT', 'TEXTAREA']
  const focused = e.target
  if (focused && focused && tags.includes(focused.tagName)) {
    const el = document.querySelector('#app')
    console.log('onBlur', e.target)
    el.style.top = null
    el.style.bottom = null
  }
}

onMounted(() => {
  state.appElement = document.querySelector('#app')
  state.appElement.style.position = 'relative'
  addEventListener("focus", onFocus, true);
  addEventListener("blur", onBlur, true);
})

onUnmounted(() => {
  removeEventListener("focus", onFocus);
  removeEventListener("blur", onBlur);
})

//

watch(() => store.state.user.isRestoring, isRestoring => {
  if (!isRestoring) {
    setTimeout(() => {
      state.splashScreenNeeded = false
    }, 500)
  } else {
    state.splashScreenNeeded = true
  }
})

onBeforeMount(async () => {
  imagesLinkCache.restore()
  document.body.classList.add('dark-theme')
  if (state.isStandalone) {
    yandexMetrika('reachGoal', 'appInstalled')
  }
})

onUnmounted(() => {
  clearInterval(state.checkInterval)
})

</script>

<style lang="scss">
@import "@/assets/variables";

.fade-enter-active,
.fade-leave-active {
  transition: opacity .3s ease, background-color .3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  background-color: transparent;
}
</style>
