<template>
  <div class="chatWrapper">
    <head-new
        :is-loading="state.isLoading"
        :back-action="back"
        :rightAction="(!state.isLoading && isSupportUser()) ? () => state.openSupportInfo = true : undefined"
        :rightIcon="require(`@/assets/icons/white_20_clock.svg`)"
    >
      <div
          class="header"
      >
        <Avatar
            size="tiny"
            :image="state.user?.preview_avatar"
            radius="8"
            @click="() => openUser(state.user)"
        />
        <Typography
            variant="v16-600"
            @click="() => openUser(state.user)"
        >
          {{ state.user?.first_name }} {{ state.user?.last_name }}
        </Typography>
      </div>
    </head-new>

    <BottomDrawer
        :is-shown="state.openSupportInfo"
        @close="state.openSupportInfo = null"
        title="Режим работы"
    >
      <Card
          style="background: #2F2F2F;margin-bottom: 16px"
      >
        <Typography
            variant="v20-700"
            color="#D3D2D2"
            style="margin-bottom: 8px"
        >
          Пн-Пт — 10:00 — 18:00 МСК
        </Typography>
        <Typography
            variant="v14-400"
            color="#D3D2D2"
        >
          Мы любим помогать нашим пользователям, не стесняйся писать
        </Typography>
      </Card>
      <AppButton
          full-width
          style="margin-bottom: 16px"
          @click="() => {state.openSupportInfo = null}"
      >
        Хорошо
      </AppButton>
    </BottomDrawer>

    <AnotherUserProfileModalLoading
        :user="state.selectedUser"
        :isFull="true"
        @close="() => {state.selectedUser = null}"
        style="position: relative;z-index: 1002"
    />

    <div
        style="height: 8px;flex-shrink: 0;"
    />

    <div
        ref="chatScrollRef"
        class="chatScroll"
    >
      <div class="main_container chat">
        <div class="space"/>

        <template v-for="item in state.chatItems">
          <template v-if="item.type === 'date'">
            <ChatDateBreak
                :text="item.data"
            />
          </template>
          <template v-if="item.type === 'message'">
            <ChatMessage
                :text="item.data.text"
                :time="item.data.time"
                :variant="item.data.variant"
                :isNew="item.data.isNew"
                :message_type="item.data.message_type"
                :currentUser="store.state.user.profile"
                :chatWithUser="state.user"
                :tags="item.data.tags"
            />
          </template>
        </template>
      </div>
    </div>

    <div class="messageInputWrapper">
      <div class="main_container messageInput">
        <div
            class="textWrapperInput"
            :class="{messageMode: state.messageMode}"
        >
          <div class="messageTitle" v-if="state.messageMode">
            <Icon
                v-if="state.messageMode === 'product'"
                class="icon"
                width="20"
                height="20"
                :icon="require(`@/assets/icons/user_20_showcase.svg`)"
                style="margin-right: 4px"
            />
            <Icon
                v-if="state.messageMode === 'looking_for'"
                class="icon"
                width="20"
                height="20"
                :icon="require(`@/assets/icons/user_20_search.svg`)"
                style="margin-right: 4px"
            />
            <Typography class="text" variant="v14-500" :class="{[state.messageMode]: state.messageMode}">
              {{ state.messageTitle }}
            </Typography>
          </div>

          <FormTextAreaGrowable
              v-if="!state.isLoading"
              full-width
              name="message"
              label=""
              :rows="1"
              only-input
              :onKeydown="onKeydown"
              @inputRef="setInputRef"
          />
          <Skeleton
              v-else
              full-width
              height="49"
              radius="12"
          />
        </div>
        <AppButton
            v-if="!state.isLoading"
            size="small"
            round
            @click="syncSubmit"
            :disabled="state.isLoading"
        >
          <Icon :icon="require(`@/assets/icons/white_24_arrowDiagonal.svg`)" rotate="-45"/>
        </AppButton>
        <Skeleton
            v-else
            style="flex-shrink: 0;"
            height="36"
            width="36"
            radius="18"
        />
      </div>
    </div>

    <bottom-drawer
        v-if="!state.isLoading"
        :isShown="state.userContactShown"
        title="Сменить рейтинг"
        @close="state.userContactShown = false"
    >
      <template v-if="state.userContactShown">
        <user-card
            :data="state.userContactShown.contact_user"
            hideActions
            style="margin-bottom: 16px"
        />
        <Evaluation
            @evaluated="evaluated"
            :user="state.userContactShown.contact_user"
            :userContact="state.userContactShown"
        />
      </template>
    </bottom-drawer>

  </div>
</template>

<script setup>
import {router} from '@/router/router'
import HeadNew from '@/components/Head.vue'
import {onBeforeMount, onBeforeUnmount, onMounted, onUnmounted, reactive, ref} from 'vue'
import {appAxios} from '@/axios'
import ChatMessage from '@/views/chat/ChatMessage.vue'
import ChatDateBreak from './ChatDateBreak.vue'
import AppButton from '@/components/UI/AppButton.vue'
import Icon from '@/components/UI/Icon.vue'
import Avatar from '@/components/profile/Avatar.vue'
import {useRoute} from 'vue-router'
import store from '@/store/store'
import moment from 'moment'
import {monthNames} from '@/configs/monthNames'
import {useForm} from 'vee-validate'
import * as Yup from 'yup'
import UserCard from '@/components/UserCard.vue'
import BottomDrawer from '@/components/BottomDrawer.vue'
import Evaluation from '@/components/Evaluation.vue'
import {getSavedMessage, removeSavedMessage} from '@/utils/createSendMessageByType'
import {everyTwoAnimationFrame} from '@/utils/test'
import {wait} from '@/utils/wait'
import Skeleton from '@/components/UI/Skeleton.vue'
import Typography from "@/components/UI/Typography.vue"
import AnotherUserProfileModalLoading from "@/views/profile/AnotherUserProfileModalLoading.vue"
import Card from "@/components/UI/Card.vue"
import FormTextAreaGrowable from "@/components/form/FormTextAreaGrowable.vue"
import {indexedStore} from '@/indexedDB/indexedStore.js'

const inputRef = ref(null)


async function getMessagesCache(chatID) {
  const messagesCache = await indexedStore.slices.chats.get(`chatsCachedChatMessages-${chatID}`)
  if (!messagesCache) {
    return null
  }
  if (messagesCache.userId !== store.state.user.profile.id) {
    return null
  }
  return messagesCache
}

async function setMessagesCache(chatID, messages) {
  let data = messages

  const messagesCache = await getMessagesCache(chatID)
  if (messagesCache) {
    const usedIds = {}
    data.forEach(chat => usedIds[chat.id] = true)

    messagesCache.data.forEach(message => {
      if (!usedIds[message.id]) {
        data.push(message)
      }
    })
  }

  data = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))

  if (data.length) {
    const newMessageCache = {
      userId: store.state.user.profile.id,
      lastCreatedAT: data.at(0).created_at,
      data,
    }
    const messagesCache = await indexedStore.slices.chats.set(`chatsCachedChatMessages-${chatID}`, newMessageCache)

    return data
  }
  return []
}


async function checkMessagesCache(chatID, ids) {
  const messagesCache = await getMessagesCache(chatID)
  if (messagesCache) {
    const indexed = {}
    ids.forEach(id => {
      indexed[id] = true
    })
    let changed = false
    messagesCache.data.forEach(message => {
      if (indexed[message.id]) {
        message.checked_at = message.created_at
        changed = true
      }
    })
    if (changed) {
      await setMessagesCache(chatID, messagesCache.data)
    }
  }
}

function strToSTR(str) {
  return str.replace(/^(https|http)?\:\/\//i, "")
}

function strToURL(str) {
  if (/^(https|http)?\:\/\//i.test(str)) {
    return str
  } else {
    return `https://${str}`
  }
}

const route = useRoute()

const chatScrollRef = ref()

function scrollOnMessageIfNeed() {
  void everyTwoAnimationFrame(() => {
    if (chatScrollRef.value) {
      chatScrollRef.value.scrollTop = chatScrollRef.value.scrollHeight
    }
  })
}

const props = defineProps({
  userId: {
    type: String,
    required: true,
  },
  productId: {
    type: String,
    required: false,
  },
  sentUID: {
    type: String,
    required: false,
  },
  ws: null,
  userContactShown: true,
})

const state = reactive({
  isMounted: false,
  isLoading: true,

  messageMode: null, // 'looking_for', // looking_for, product
  messageTitle: null, //'Отклик на: «123afdsaf adsf dsadf adsdf adsdf dsaf asdfd dsaf dsaf adsf dasfds»',

  userId: null,
  user: null,
  chat: null,
  room_name: null,
  chatItems: [
    // {type: 'message', data: ''},
    // {type: 'looking_for', data: ''},
  ],
  lastDay: null,
  toSent: [],
  toCheck: [],
  interval: null,
  selectedUser: null,
  openSupportInfo: false,
  isScrollBottom: false,
})

function onResize() {
  if(state.isScrollBottom) {
    scrollOnMessageIfNeed()
  }
}

onMounted(() => {
  onResize()
  chatScrollRef.value.addEventListener('scroll', () => {
    state.isScrollBottom = Math.abs(chatScrollRef.value.scrollTop + chatScrollRef.value.clientHeight - chatScrollRef.value.scrollHeight) < 8
  })
  addEventListener("resize", onResize)
})

onUnmounted(() => {
  removeEventListener("resize", onResize)
})

onMounted(() => {
  state.interval = setInterval(async () => {
    if (state.toCheck.length) {
      const messageIds = [...state.toCheck]
      if (messageIds.length === 0) {
        return
      }
      state.toCheck = []
      try {
        appAxios.chat.markChecked(state.chat.id, messageIds)
        checkMessagesCache(state.chat.id, messageIds)
      } catch (e) {
        state.toCheck.push(...messageIds)
      }
    }
  }, 1e3)
})

onUnmounted(() => {
  clearInterval(state.interval)
})

const {handleSubmit, setErrors, isSubmitting, setFieldValue, setValues} = useForm({
  validationSchema: Yup.object({
    message: Yup.string()
  }),
});

async function back() {
  await router.push({name: route.meta.returnName, params: {userId: props.userId, productId: props.productId}})
}

function isSupportUser() {
  if (!state.user) {
    return false
  }
  return store.state.user.directories.support_chat_user.id === state.user.id
}

function openUser(user) {
  if (store.state.user.directories.support_chat_user.id === user.id) {
    return
  }
  router.push({name: 'CardHolderUserProfileChat', params: {userId: props.userId}})
}

function addMessage(message) {
  const time = moment(message.created_at)
  // push date
  if (!state.lastDay || time.format('YYYY-MM-DD') !== state.lastDay) {
    state.chatItems.push({
      type: 'date',
      data: `${Number(time.format('DD'))} ${monthNames[Number(time.format('MM'))]}`,
    })
    state.lastDay = time.format('YYYY-MM-DD')
  }
  // push message
  const sent_by_id = message.sent_by?.id ?? message.sent_by_id

  if (sent_by_id !== state.userId && !message.checked_at) {
    state.toCheck.push(message.id || message.message_id)
  }

  let text = message.message
  if (text.includes('title') && text.includes('text') && text.includes('type')) {
    try {
      const json = JSON.parse(message.message)
      if(json.title && json.type) {
        text = json.text
      }
    } catch (e) {
      console.log('e', e)
      // can't parse
    }
  }

  const regExp = /(https:|http:|\S*\.\S+\S+)\S*/gm
  const result = [...text.matchAll(regExp)]
  const tags = []

  let index = 0
  result.forEach(res => {
    const str = res[0]
    if (index < res.index) {
      const value = text.slice(index, res.index)
      tags.push({
        type: 'text',
        value: value ? value : '',
      })
      index = res.index
    }
    tags.push({
      type: /(\S*\.)\S{1,4}/.test(str) ? 'link' : 'text',
      value: strToSTR(str),
      href: strToURL(str),
    })
    index += str.length
  })
  if (index < text.length) {
    const value = text.slice(index, message.message.length)
    tags.push({
      type: 'text',
      value: value ? value : '',
    })
  }
  state.chatItems.push({
    type: 'message',
    data: {
      message_type: message.message_type,
      text: message.message,
      tags,
      time: time.format('HH:mm'),
      variant: sent_by_id !== state.userId ? 'response' : 'answer', // response message.sent_by_id
      isNew: message.isNew,
    }
  })
}

function sendMessage(messageText, message_type = 'message') {
  const json = JSON.stringify({
    token: store.state.user.token,
    chat_id: state.chat.id,
    sent_by_id: state.userId,
    message_type,
    message: messageText,
  })
  try {
    state.ws.send(json)
  } catch (e) {
    console.error(e)
    state.toSent.push({
      message_type: json.message_type,
      message: json.message,
    })
  }
}

function createWebSocket() {
  const url = new URL(`${import.meta.env.VUE_APP_WS_URL}/ws/chat/${state.room_name}/?user_id=${state.userId}`)

  state.ws = new WebSocket(url.toString())

  state.ws.onopen = async () => {
    while (state.toSent.length) {
      const toSent = state.toSent.at(0)
      state.toSent = state.toSent.filter(i => i !== toSent)
      sendMessage(toSent.message, toSent.message_type)
      await wait(1000)
    }
  }

  state.ws.onerror = () => {
    try {
      state.ws.close()
    } catch (e) {
      console.error(e)
    }
    if (state.isMounted) {
      createWebSocket()
    }
  }

  state.ws.onmessage = message => {
    const json = JSON.parse(message.data)
    scrollOnMessageIfNeed()
    json.isNew = true
    addMessage(json)
  }
}

onBeforeUnmount(() => {
  state.isMounted = false
  try {
    state.ws.close()
  } catch (e) {
    console.log('close', e)
  }
})

onBeforeMount(async () => {
  state.isMounted = true

  try {
    state.userId = store.state.user.profile.id
    const chatRoomRes = await appAxios.chat.start(props.userId)
    state.user = chatRoomRes.data.user_to.id === state.userId ? chatRoomRes.data.user_from : chatRoomRes.data.user_to
    state.chat = chatRoomRes.data
    state.room_name = chatRoomRes.data.room_name

    const messagesCache = await getMessagesCache(state.chat.id)
    let res = null

    if (messagesCache) {
      res = await appAxios.chat.fetchMessages(
          state.chat.id,
          messagesCache.lastCreatedAT,
          undefined,
      )
    } else {
      res = await appAxios.chat.fetchMessages(
          state.chat.id,
          undefined,
          undefined,
      )
    }
    const messages = await setMessagesCache(state.chat.id, res.data)

    if (props.sentUID) {
      const toSent = getSavedMessage({
        uid: props.sentUID,
        sent_by_id: state.userId,
        user_to_id: state.user.id,
      })
      if (toSent) {
        removeSavedMessage(props.sentUID)
        const {message_type, message} = toSent
        state.messageMode = message_type
        state.messageTitle = message
      }
    }

    messages.reverse().forEach(message => {
      message.isNew = false
      addMessage(message)
    })

    createWebSocket()

    scrollOnMessageIfNeed()
  } catch (e) {
    console.error(e)
    // todo: error?
  } finally {
    state.isLoading = false
  }
})

const onSubmit = handleSubmit(async values => {
  if (!values.message && !state.messageMode) {
    return
  }
  if (state.messageMode) {
    sendMessage(JSON.stringify({
      type: state.messageMode,
      title: state.messageTitle,
      text: values.message ?? '',
    }), state.messageMode)
    state.messageMode = null
    state.messageTitle = null
  } else {
    sendMessage(values.message)
  }
  setValues({message: ''})
  // scrollOnMessageIfNeed()
  if (chatScrollRef.value) {
    chatScrollRef.value.scrollTop = chatScrollRef.value.scrollHeight
  }
})

const syncSubmit = (values) => {
  if (inputRef.value) {
    inputRef.value.focus()
  }
  onSubmit(values)
}

async function evaluated(rate, userContact) {
  try {
    console.log('r', rate, userContact)
    state.userContactShown = false
  } catch (e) {
    console.error(e)
  }
}

function onKeydown(e) {
  scrollOnMessageIfNeed()
}

function setInputRef(ref) {
  inputRef.value = ref
}

</script>

<style lang="scss" scoped>

.header {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-left: 12px;
}

.chatWrapper {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  min-height: 100vh;
  max-height: 100dvh;
  min-height: 100dvh;
}

.chatScroll {
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.chat {
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  padding-top: 16px;
  flex-grow: 1;

  & > *:first-child {
    margin-top: 0;
  }

  .space {
    flex-grow: 1;
  }
}

.chat .space:first-child + div {
  margin-top: 0;
}

.messageInputWrapper {
  //height: 82px;
  border-top: 1px solid #2b2b2b;
  flex-shrink: 0;
  display: flex;
  margin-bottom: 6px;
}

.messageInput {
  display: flex;
  gap: 8px;
  align-items: flex-end;
  padding-top: 8px;
  padding-bottom: 16px;
}

.textWrapperInput {
  min-width: calc(100% - 40px);
  max-width: calc(100% - 40px);
  position: relative;

  &.messageMode {
    .messageTitle {
      display: flex;
      padding: 4px 0 10px 0;
      align-items: center;

      .text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: calc(100% - 24px);

        &.looking_for {
          color: #7EC9FF;
        }
        &.product {
          color: #54C35F;
        }
      }

      & > * {
        flex-shrink: 0;
      }
    }
  }
}

</style>
